import React from "react"
import { Router } from "@reach/router"
import ErrorPage from "./404"
import Dashboard from "./ci/dashboard"
import Index from "./ci/ci"
import { SystemAdminRoute } from "../components/admin-route"
import ProtectedRoute from "../components/protected-route"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import { Error } from "../components/error"

const Secure = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <ProtectedRoute path="/ci" component={Index} />
        <ProtectedRoute path="/ci/dashboard" component={Dashboard}/>
        <ErrorPage default />
      </Router>
    </>
  )
}

export default Secure

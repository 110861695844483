import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useAuth0 } from "@auth0/auth0-react"
import { PropagateLoader } from "react-spinners"
import { css } from "glamor"
import { Row, Col, Table  } from "reactstrap"
import { Select, MenuItem} from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import Pagination from "@material-ui/lab/Pagination"
import * as Sentry from "@sentry/gatsby"
import { fetch_get } from "../../utils/fetch"
import _ from "lodash"

const CreatePage = () => {
  const [gotError, setGotError] = useState(false)
  const [templates, setTemplates] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [noTemplates, setNoTemplates] = useState("There are no templates.")
  const [searchCounter, setSearchCounter] = useState(0)
  const [loading, setLoading] = useState(true)
  const { getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    let url = `CI/template/list?page=${currentPage}&limit=${pageSize}`
    setLoading(true)
    setGotError(false)
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setLoading(false)
        setTemplates(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
      })
      .catch(error => {
        setLoading(false)
        setTemplates([
        ])
        setNoTemplates("An error occurred while getting the list of latest templates.")
        Sentry.captureException(error)
        console.log(`Error while getting latest templates: ${error}`)
        setGotError(true)
      })
  }, [searchCounter])

  function onPageChange(event, page) {
    setCurrentPage(page)
    setSearchCounter(n => n + 1)
  }

  function onPageSizeChange(event) {
    const size = parseInt(event.target.value, 10)
    setPageSize(size)
    setCurrentPage(1)
    setSearchCounter(n => n + 1)
  }

  return (
    <Layout pageTitle="CI">
        <>
          <Header contentPage={true} className="contentPage">
            <h1 className="display-4 font-weight-bold">Welcome CodeLinaro CI</h1>
          </Header>
          <Content>
            <div>
              <p>CodeLinaro has developed a flexible robust infrastructure supporting CI (Continuous Integration). When performing a "build", we are actually running a pipeline through our AWS/GitLab infrastructure. All pipelines/builds are independent and use ephemeral (temporary) resources which then terminate after the pipeline completes. This is to save on operating costs to CodeLinaro as well as the our customers.</p>
              <p>Getting your project set up with CI has never been easier! Along with GitLab, CodeLinaro provides a growing selection of CI templates which have been preconfigured to work with our CI system. Please select the name of the appropriate pipeline template below to read more documentation on what jobs said pipeline will run. If it's the pipeline you need, CodeLinaro will configure your project to use it.</p>
              <div style={{fontWeight: 600, fontStyle: 'italic'}}>IMPORTANT</div>
              <p style={{fontStyle: 'italic'}}>ALL JOBS AND PIPELINES REPRESENTED HERE ARE PROVIDED BY CODELINARO DEVELOPERS AND OTHERS WITHIN THE OPEN SOURCE COMMUNITY. CODELINARO IS NOT RESPONSIBLE FOR THE BEHAVIOR OF THESE PIPELINES. THEY ARE HERE TO ONLY PROVIDE GUIDANCE WHICH MIGHT NEED UPDATING PERIODICALLY. IF THERE'S AN ISSUE CONCERNING THE CI INFRASTRUCTURE, THEN PLEASE CONTACT support@codelinaro.org. HOWEVER, YOU ARE RESPONSIBLE FOR THE SUPPORT OF YOUR PIPELINES.</p>
            </div>
            <br/>
            <h1 className="display-4 font-weight-bold" style={{marginBottom: 40}}>Preconfigured CodeLinaro Pipelines</h1>
            <div style={{position:'relative'}}>
            {loading ? (
                <Col
                  className="d-flex justify-content-center align-items-center"
                  xs={{ size: 12 }}
                  {...css(styles.loader)}
                >
                  <PropagateLoader
                    className="align-self-center"
                    size={20}
                    color={"#9c3"}
                    loading={true}
                  />
                </Col>
              ) : (
                <></>
              )}
              <div style={{ opacity: loading ? ".5" : "1" }}>
                {!loading && gotError ? (
                  <p>
                    An error has occurred while trying to retrieve the latest templates. If this
                    continues, please raise a support ticket.
                  </p>
                ) : (
                  <>
                    <div id="templatesWrapper">
                      {templates.length > 0 && (
                        <>
                          <div className="float-right">
                            <div className="form-inline">
                              <Pagination
                                count={pageCount}
                                page={currentPage}
                                onChange={onPageChange}
                                variant="outlined"
                                shape="rounded"
                              />

                              <Select
                                labelId="pageSize"
                                id="page-size"
                                value={pageSize}
                                onChange={onPageSizeChange}
                                className="pageSizeSelect"
                              >
                                <MenuItem value={10}>10 / page</MenuItem>
                                <MenuItem value={50}>50 / page</MenuItem>
                                <MenuItem value={100}>100 / page</MenuItem>
                              </Select>
                            </div>
                          </div>
                          <br />
                          <br />
                        </>
                      )}
                      <Row>
                        <Col>
                          <div className="table-border" style={{ overflow: "auto" }}>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Template</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {templates.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <a
                                          target="_blank"
                                          href={item.template_link}
                                        >
                                          {item.template}
                                        </a>
                                      </td>
                                      <td>{item.describe}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      {templates.length > 0 && (
                        <div className="float-right">
                          <div className="form-inline">
                            <Pagination
                              count={pageCount}
                              page={currentPage}
                              onChange={onPageChange}
                              variant="outlined"
                              shape="rounded"
                            />

                            <Select
                              labelId="pageSize"
                              id="page-size"
                              value={pageSize}
                              onChange={onPageSizeChange}
                              className="pageSizeSelect"
                            >
                              <MenuItem value={10}>10 / page</MenuItem>
                              <MenuItem value={50}>50 / page</MenuItem>
                              <MenuItem value={100}>100 / page</MenuItem>
                            </Select>
                          </div>
                        </div>
                      )}
                      {templates.length === 0 && (
                        <Row>
                          <Col xs={{ size: 12 }}>
                            <p {...css(styles.noData)}>{noTemplates}</p>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </>
                )}
                </div>
                </div>
          </Content>
        </>
    </Layout>
  )
}

export default CreatePage
const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
    display: "block",
    width: "100%",
    height: "100%",
  },
  noData: {
    color: "#bfbfbf",
    fontSize: 18,
  },
}